import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class DirectService {


  apiurl = AppConfigService.settings.apiServer.apiurl;
  aliapiurl=AppConfigService.settings.apiServer.aliapiurl;

  constructor(private http: HttpClient) { }
  getDirectCustomer(): Observable<any[]> { 
    let url = this.aliapiurl + "direct/directgrid";
    return this.http.get<any[]>(url);
  }
  

  getDirectPlans(): Observable<any[]> { 
    let url = this.aliapiurl + "direct/directplan";
    return this.http.get<any[]>(url);
  }

  getDirectReports(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdiereports";
    return this.http.get<any[]>(url);
  }
  getDirectSponsor(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieSponsor";
    return this.http.get<any[]>(url);
  }
  getDirectParentSponsor(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieParentSponsor";
    return this.http.get<any[]>(url);
  }
  getDirectDivision(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieDivision";
    return this.http.get<any[]>(url);
  }
  getDirectPlanReportMapping(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieplanreportsmapping";
    return this.http.get<any[]>(url);
  }
  getDirectPlanEmployerMapping(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieplanemployermapping";
    return this.http.get<any[]>(url);
  }
}
