import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule }  from '@angular/common';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterModule, ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  
  styleUrls: ['./passwordreset.component.css','../../../../scss/vendors/toastr/toastr.scss','../../../../../node_modules/ladda/dist/ladda-themeless.min.css'],
  encapsulation: ViewEncapsulation.None,host: {'class': 'd-flex flex-column app-forgotpassword'},
  providers: [ToasterService]
})
export class PasswordresetComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
     submitted = false;
     returnUrl: string;
     error = '';
     currentyear: number = new Date().getFullYear();
     private toasterService: ToasterService;
     public toasterconfig: ToasterConfig =
       new ToasterConfig({
         tapToDismiss: true,
         timeout: 5000
       });
     constructor(private authS: AuthService,private formBuilder: FormBuilder,private route: ActivatedRoute,
      private router: Router,toasterService: ToasterService){this.toasterService = toasterService;}

ngOnInit(): void {
  this.loginForm = this.formBuilder.group({
    password: ['', [Validators.required]],
    confirmpassword:['', [Validators.required]]

     
}, {
  validator: this.password.bind(this)
    });
}
get f() { return this.loginForm.controls; }
password(formGroup: FormGroup) {
  const { value: password } = formGroup.get('password');
  const { value: confirmPassword } = formGroup.get('confirmpassword');
  if (password === confirmPassword) {
    formGroup.get('confirmpassword').setErrors(null);
} else {
  formGroup.get('confirmpassword').setErrors({ confirmedValidator: true });

}

 // return password === confirmPassword ? null : { passwordNotMatch: true };
}
onSubmit() {
  let self=this;
    this.submitted = true;
  var token=  this.route.snapshot.params.token; 
    if (this.loginForm.invalid) { 
               return;
           }
           this.loading = true;
     this.authS.resetpassword(this.f.password.value,token).subscribe(data=>{
      this.loading = false;
      this.submitted=false;
      this.toasterService.pop('success', 'Success', 'Password has been changed successfully.');
      this.router.navigate(['/login']); 
           });
}

}
