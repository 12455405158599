import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';

import { BehaviorSubject, Observable, of, EMPTY, from, Subject } from 'rxjs';

import { mergeMap, take, catchError, map } from 'rxjs/operators';
import { ServiceproviderService } from '../serviceprovider.service';
@Injectable({
    providedIn: 'root',
})

export class Pcompany implements Resolve<any>{
    constructor(private router: Router, private ps: ServiceproviderService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return this.ps.getProviderCompanies();
    }
}
