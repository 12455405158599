import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';


import { BehaviorSubject,Observable, of, forkJoin}  from 'rxjs';

import { mergeMap, take,catchError,map }         from 'rxjs/operators';

import { BirdieService } from '../birdie.service';

@Injectable({
  providedIn: 'root',
})
export class BirdieGrid implements Resolve<any> {
     constructor(private router: Router, private admin:BirdieService) {  }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 
            return this.admin.getBirdieCustomer();
        }
}
