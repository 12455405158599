import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class ServiceproviderService {
  apiurl = AppConfigService.settings.apiServer.apiurl;
  aliapiurl=AppConfigService.settings.apiServer.aliapiurl;
  constructor(private http: HttpClient) { }

  getServiceProver(filterdata: object): Observable<any[]> {
    let url = this.apiurl + "providers/GetFilteredProviders";
    return this.http.post<any[]>(url, filterdata);
  }
  getwalmartPlan(filterdata: object): Observable<any[]> {
    let url = this.apiurl + "WalmartProviderPlans";
    return this.http.get<any[]>(url, filterdata);
  }

  getdpcProvider(filterdata:object):Observable<any[]>{
    let url=this.apiurl+"providers/GetDPCProviders"; 
    return this.http.get<any[]>(url,filterdata); 
  }

  getvpcProvider(filterdata:object):Observable<any[]>{
    let url=this.apiurl+"providers/GetVPCProviders"; 
    return this.http.get<any[]>(url,filterdata); 
  }
  getvcProvider(filterdata:object):Observable<any[]>{
    let url=this.apiurl+"providers/GetVCProviders"; 
    return this.http.get<any[]>(url,filterdata); 
  }



  getServiceProviderPk(pk): Observable<any[]> {
    let url = this.apiurl + "providers/" + pk;
    return this.http.get<any[]>(url);
  }


  getProviderCompanies(): Observable<any[]> {
    let url = this.apiurl + "ProviderCompanies";
    return this.http.get<any[]>(url);
  }

  getProviderCompaniesByPk(pk): Observable<any[]> {
    let url = this.apiurl + "ProviderCompanies/" + pk;
    return this.http.get<any[]>(url);
  }

  getProviderGroup(): Observable<any[]> {
    let url = this.apiurl + "ProviderGroups";
    return this.http.get<any[]>(url);
  }

  getProviderGroupByPk(pk): Observable<any[]> {
    let url = this.apiurl + "ProviderGroups/" + pk;
    return this.http.get<any[]>(url);
  }
  getPCP(filter: object): Observable<any[]> {

    let url = this.apiurl + "CustomerProviders/GetFilteredCustomerProductProvider";
    return this.http.post<any[]>(url, filter);
  }
  getMPP(filter: object): Observable<any[]> { 
    let url = this.apiurl + "PlanProductProviders/Sp_Get_FilteredPlanProductProvider";
    return this.http.post<any[]>(url, filter);
  }
  getMPPByPk(pk: string): Observable<any[]> { 
    let url = this.apiurl + "PlanProductProviders/"+pk;
    return this.http.get<any[]>(url);
  }
  getMappingRule(filter: object): Observable<any[]> {

    let url = this.apiurl + "AssignProviderRules/GetProviderMappingRules";
    return this.http.post<any[]>(url, filter);
  }


}
