import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class SettingService {
  apiurl = AppConfigService.settings.apiServer.apiurl;
  aliapiurl=AppConfigService.settings.apiServer.aliapiurl;

  constructor(private http: HttpClient) { }
  getServiceLocations(): Observable<any[]> {
    let url = this.apiurl + "ServiceLocations";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }
getDivision(): Observable<any[]>{
let url=this.aliapiurl+"setting/division";
const headers = { 'skip': 'true' };
return this.http.get<any[]>(url,{headers}).pipe(
  map((response: any) => {

    return response;
  })
);
}
getEmployer(): Observable<any[]>{
  let url=this.aliapiurl+"setting/employer";
  const headers = { 'skip': 'true' };
  return this.http.get<any[]>(url,{headers}).pipe(
    map((response: any) => {
  
      return response;
    })
  );
  }
  getEmployerbySponsor(SponserKey): Observable<any[]>{ 
    let url=this.aliapiurl+"setting/employer?sponsor="+SponserKey;
    const headers = { 'skip': 'true' };
    return this.http.get<any[]>(url,{headers}).pipe(
      map((response: any) => {
    
        return response;
      })
    );
    }
  getService(): Observable<any[]> {
    let url = this.apiurl + "Services";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );
  }


  getSpecialty(): Observable<any[]> {
    let url = this.apiurl + "Speciality";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }

  getStates(): Observable<any[]> {
    let url = this.apiurl + "States";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }

  getLeadSource(): Observable<any[]> {
    let url = this.apiurl + "leadsources";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }

  getPlan(): Observable<any[]> {
    let url = this.apiurl + "plans";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }


  getSponsors(by): Observable<any[]> {
    /*
    let url = this.apiurl + "sponsors";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;*/

    
    let url=this.aliapiurl+"setting/sponsorgrid?by="+by;
    const headers = { 'skip': 'true' };
    return this.http.get<any[]>(url,{headers}).pipe(
      map((response: any) => {
    
        return response;
      })
    );
  }

  getSponsorsByPk(pk): Observable<any[]> {
    let url = this.apiurl + "sponsors/" + pk;

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }

  getProviders(): Observable<any[]> {
    let url = this.apiurl + "providers";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }

  getSMSHistroy(): Observable<any[]>{
    let url=this.aliapiurl+"setting/smshistory";
    const headers = { 'skip': 'true' };
    return this.http.get<any[]>(url,{headers}).pipe(
      map((response: any) => {
    
        return response;
      })
    );
    }
    getEmailHistroy(startdate:String,enddate:String): Observable<any[]>{
      var query="";
      if(startdate!='' && enddate!=''){
    
        query+="sdate="+startdate+"&edate="+enddate;
      }
      let url=this.aliapiurl+"setting/emailhistory"+((query!='')?'?'+query:'');
      const headers = { 'skip': 'true' };
      return this.http.get<any[]>(url,{headers}).pipe(
        map((response: any) => {
      
          return response;
        })
      );
      }
      getEmailSummary(query:string): Observable<any[]>{
         
        let url=this.aliapiurl+"setting/emailsummary"+((query!='')?'?'+query:'');
        const headers = { 'skip': 'true' };
        return this.http.get<any[]>(url,{headers}).pipe(
          map((response: any) => {
        
            return response;
          })
        );
        }
        getVisitSummary(query:string): Observable<any[]>{
          
          let url=this.aliapiurl+"setting/visitsummary"+((query!='')?'?'+query:'');
          const headers = { 'skip': 'true' };
          return this.http.get<any[]>(url,{headers}).pipe(
            map((response: any) => {
          
              return response;
            })
          );
          }

          getSMSummary(query:string): Observable<any[]>{
          
            let url=this.aliapiurl+"setting/smssummary"+((query!='')?'?'+query:'');
            const headers = { 'skip': 'true' };
            return this.http.get<any[]>(url,{headers}).pipe(
              map((response: any) => {
            
                return response;
              })
            );
            }
            getvistdetailSummary(query:string): Observable<any[]>{
          
              let url=this.aliapiurl+"setting/visitdetailssummary"+((query!='')?'?'+query:'');
              const headers = { 'skip': 'true' };
              return this.http.get<any[]>(url,{headers}).pipe(
                map((response: any) => {
              
                  return response;
                })
              );
              }

              
  getPushNotification(): Observable<any[]>{
    let url=this.aliapiurl+"setting/copilotpushnotification";
    const headers = { 'skip': 'true' };
    return this.http.get<any[]>(url,{headers}).pipe(
      map((response: any) => {
    
        return response;
      })
    );
  }
  getEmployerDomains(): Observable<any[]> {
    let url = this.aliapiurl + "setting/employerdomains";
    const headers = { 'skip': 'true' };
    return this.http.get<any[]>(url, { headers }).pipe(
      map((response: any) => {

        return response;
      })
    );
  }

}
