import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class DoctorsService {

  apiurl = AppConfigService.settings.apiServer.apiurl;
  aapiurl = AppConfigService.settings.apiServer.aliapiurl;

  constructor(private http: HttpClient) { }

  getDoctors(): Observable<any[]> {
    let url = this.apiurl + "doctors";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }

  getDoctorPk(pk): Observable<any[]> {
    let url = this.aapiurl + "doctor/single?id=" + pk;

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }
}
