<div *ngIf="isdisplay">
<div class="popover fade {{Ishow}} bs-popover-left shadow"  #popover role="tooltip">
    <div class="arrow" style=" transform: translateY(-50%); top: 50%; "></div>
    <h3 class="popover-header">{{popoption.title}}</h3>
    <div class="popover-body">{{popoption.content}}</div>
    <div class="popover-footer border-top rounded-bottom" *ngIf="popoption.isconfirm">
    <div class="px-3 py-2">
        <div class="">
        <button class="btn btn-warning btn-sm" (click)="no($event)" type="button">No</button>
        <button class="btn btn-success btn-sm" (click)="yes($event)" type="button">Yes</button>
        </div>
        </div>
    </div>
</div>
    </div>