import { Component, OnInit,Output, EventEmitter,ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavBtn } from './navbtn';
import { NavbtnService } from './navbtn.service';
import { HttpClient  } from '@angular/common/http';
import { ToasterModule, ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
@Component({
  selector: 'app-navbtn',
  templateUrl: './navbtn.component.html',
  styleUrls: ['./navbtn.component.css','../../../../node_modules/ladda/dist/ladda-themeless.min.css','../../../scss/vendors/toastr/toastr.scss'],
       encapsulation: ViewEncapsulation.None,
     providers: [ToasterService]
})
export class NavbtnComponent implements OnInit {
 navbts: NavBtn[] = [];
    
      private toasterService: ToasterService;
    public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 5000
    });
        subscription: Subscription;
 
  constructor(private nb:NavbtnService,private http:HttpClient,toasterService: ToasterService) {this.toasterService = toasterService; }

  ngOnInit(): void {
      
        this.subscription = this.nb.onAdd().subscribe(btns => {
    
      this.navbts=[];
    
     
      if(btns.btn){ this.navbts.push(btns);}
        
      
  
  })
  }
   removeBtn(navbtn: NavBtn) {
      
        this.navbts = this.navbts.filter(x => x !== navbtn);
    }

eventControl(event,btn){
  
this.nb.callbackclick(event,btn);
   
    

 
}





}
