import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: '',
  
  },{
    name: 'Members',
    url: '/members',
    icon: '',
   },{
    name: 'Orders',
    url: '/order',
    icon: '',
   },{
    name: 'Doctors',
    url: '/doctors',
    icon: '',
   },{
    name: 'Emails',
 url: '/email',
    icon: '',
        children: [   {
    name: 'Email Sent',
    url: '/email/sent',
    icon: '',
   }
        ]
   },
    {
    name: 'Plans & Products',
   url:"/pp",
    icon: '',
        children: [
         {
    name: 'Plans',
    url: '/pp/plans',
    icon: '',
   },  {
    name: 'Products',
    url: '/pp/products',
    icon: '',
   }, {
    name: 'Products Plan Mapping',
    url: '/pp/productsplans',
    icon: '',
   }
        ]
    }
    ,{
    name: 'Sponsors',
    url: '/settings/sponsor',
    icon: '',
   },
    {
    name: 'Services & Locations',
   url:"/sl",
    icon: '',
        children: [
           {
    name: 'Services',
    url: '/sl/services',
    icon: '',
   },{
    name: 'Service Locations',
    url: '/sl/servicelocation',
    icon: '',
   }
        ]
    }
    ,
    {
    name: 'Alicorn Users, Menu',
   url:"/admin",
    icon: '',
        children: [
            {
    name: 'Users',
    url: '/admin/users',
    icon: '',
   },  {
    name: 'Roles',
    url: '/admin/roles',
    icon: '',
   },  {
    name: 'Menus',
    url: '/admin/menus',
    icon: '',
   },{
    name: 'Role Menu Mapping',
    url: '/admin/rolemapping',
    icon: '',
   }
        ]
    }
    , {
    name: 'Masters',
   url:"/settings",
    icon: '',
        children: [
           {
    name: 'Providers',
    url: '/settings/providers',
    icon: '',
   },{
    name: 'Lead Source',
    url: '/settings/leadsource',
    icon: '',
   },{
    name: 'States',
    url: '/settings/states',
    icon: '',
   },   {
    name: 'Email Types',
    url: '/email',
    icon: '',
   },{
    name: 'Speciality',
    url: '/settings/speciality',
    icon: '',
   }
        ]
   }
];
