import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class BirdieService {
  apiurl = AppConfigService.settings.apiServer.apiurl;
  aliapiurl=AppConfigService.settings.apiServer.aliapiurl;

  constructor(private http: HttpClient) { }
  getBirdieCustomer(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdiegrid";
    return this.http.get<any[]>(url);
  }
  

  getBirdiePlans(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieplan";
    return this.http.get<any[]>(url);
  }

  getBirdieReports(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdiereports";
    return this.http.get<any[]>(url);
  }
  getBirdieSponsor(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieSponsor";
    return this.http.get<any[]>(url);
  }
  getBirdieParentSponsor(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieParentSponsor";
    return this.http.get<any[]>(url);
  }
  getBirdieDivision(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieDivision";
    return this.http.get<any[]>(url);
  }
  getBirdiePlanReportMapping(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieplanreportsmapping";
    return this.http.get<any[]>(url);
  }
  getBirdiePlanEmployerMapping(): Observable<any[]> { 
    let url = this.aliapiurl + "birdie/birdieplanemployermapping";
    return this.http.get<any[]>(url);
  }
}
