import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class AdminService {

  apiurl = AppConfigService.settings.apiServer.apiurl;

  constructor(private http: HttpClient) { }

  getRoles(): Observable<any[]> {
    let url = this.apiurl + "roles";

    return this.http.get<any[]>(url);
  }

  getMenus(): Observable<any[]> {
    let url = this.apiurl + "menus";

    return this.http.get<any[]>(url);
  }


  getMenusPk(pk): Observable<any[]> {
    let url = this.apiurl + "menus/" + pk;

    return this.http.get<any[]>(url);
  }
  getInitMenus(): Observable<any[]> {
    let url = this.apiurl + "menus";

    return this.http.get<any[]>(url);
  }
  groupBy(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]['children'] = [];
    }


    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];


        if (mappedElem.parentId) {

          mappedArr[mappedElem['parentId']]['children'].push(mappedElem);
        } else {

          tree.push(mappedElem);

        }

      }


    }
    /* tree.map(itm=>{
        if(itm.children.length==0){
        delete itm["children"];
            }else{
             itm.children.map(child=>{
                 if(child.children.length==0){
        delete child["children"];
            }
               return child;  
             });   
            }
        return itm;
     });*/


    return tree;

  }


  getRoleMenus(): Observable<any[]> {
    let url = this.apiurl + "rolemenus";

    return this.http.get<any[]>(url);
  }

  getRoleMenusPK(PK): Observable<any[]> {
    let url = this.apiurl + "rolemenus/" + PK;

    return this.http.get<any[]>(url);
  }


  getAdminUsers(): Observable<any[]> {
    let url = this.apiurl + "AlicornUsers";

    return this.http.get<any[]>(url);
  }

  geAdminPk(pk): Observable<any[]> {
    let url = this.apiurl + "AlicornUsers/" + pk;

    return this.http.get<any[]>(url);
  }
}
