import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from './app-config.service';
import { AdminUser } from '../adminuser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private UserSubject: BehaviorSubject<any>;
  public User: Observable<any>;

  constructor(private http: HttpClient) {
    this.UserSubject = new BehaviorSubject<AdminUser>(JSON.parse(localStorage.getItem('admin')));
    this.User = this.UserSubject.asObservable();
  }

  public get AdminUserValue(): any {
    return this.UserSubject.value;
  }

  resetpassword(password,token){
    var formData: any = new FormData();
    formData.append("Password", password);
    formData.append("Token", token);
    return this.http.post<any>(AppConfigService.settings.apiServer.aliapiurl + 'admin/passwordreset', formData)
    .pipe(map(user => {
      return user;
    }));
  }

  forgotpassword(username: string){
    var formData: any = new FormData();
    formData.append("Username", username);
    return this.http.post<any>(AppConfigService.settings.apiServer.aliapiurl + 'admin/forgotpassword', formData)
    .pipe(map(user => {
      return user;
    }));
    
  }

  login(username: string, password: string) {
    var formData: any = new FormData();
    formData.append("Username", username);
    formData.append("Password", password);
    var postpara = {
      Username: username,
      Password: password
    }
    return this.http.post<any>(AppConfigService.settings.apiServer.apiurl + 'userauth/login', postpara)
      .pipe(map(user => {

        if (user && user.token) {

          localStorage.setItem('admin', JSON.stringify(user));
          this.UserSubject.next(user);
          return user;
        } else {

        }

        return false;
      }));
  }

  logout() {

    localStorage.removeItem('admin');
    this.UserSubject.next(null);

  }

  usermenu() {
    const Userdata = this.AdminUserValue;
    if (Userdata != null) {
      const jwtToken = JSON.parse(atob(Userdata.token.split('.')[1]));
      var postpara = {
        SecondaryKey: jwtToken.role
      }
      return this.http.post<any>(AppConfigService.settings.apiServer.apiurl + 'RoleMenus/GetMenusbyRoles/', postpara);

    }
  }
}
