import { Component, OnInit,ElementRef,ViewChild,ChangeDetectorRef } from '@angular/core';
import { PoverService } from './pover.service';
import { BehaviorSubject, Observable,throwError,Subscription } from 'rxjs';

import positionElement from 'position-element';
declare var $: any;
@Component({
  selector: 'app-pover',
  templateUrl: './pover.component.html',
  styleUrls: ['./pover.component.css']
})
export class PoverComponent implements OnInit {
 subscription: Subscription;
    @ViewChild('popover', { static: false }) popoverbox: ElementRef;
    popoption={
     isconfirm:false,
        title:"", 
        content:"",
        elem:null,
        isShow:false,
        yescallback:undefined,
        nocallback:undefined
    };
    isdisplay:boolean=false;
    Ishow:string="";
  constructor(private POPS: PoverService,private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
         
  this.subscription=this.POPS.onPopOver().subscribe(Isshow=>{
      this.isdisplay=Isshow.isShow;
     this.Ishow="";
       this.changeDetector.detectChanges();
     var selectPositon=Isshow.elem;
      
      this.popoption=Isshow;
      const selPos=selectPositon.getBoundingClientRect();
       setTimeout(()=>{
         this.Ishow=(Isshow.isShow)?"show":"";
      if(Isshow.isShow){
     var poppx=this.popoverbox.nativeElement;
      const poppxPo=poppx.getBoundingClientRect();
      const psotionS=this.getAbsPosition(selectPositon);
      
      var left=(psotionS[1]-poppxPo.width)-15,
      top=(psotionS[0]+(selPos.height/2));
     
      poppx.setAttribute("style","left:"+left+"px;top:"+top+"px;transform: translateY(-50%);");
    }

  },200);
  });
  }
  no(event){
    this.popoption.nocallback();
    this.Ishow="";
      this.isdisplay=false;  
  }
  yes(event){
  this.popoption.yescallback();
      this.Ishow="";
      this.isdisplay=false;
      
  }
  
  getAbsPosition(el){
    var el2 = el;
    var curtop = 0;
    var curleft = 0;
    if (document.getElementById || document.all) {
        do  {
            curleft += el.offsetLeft-el.scrollLeft;
            curtop += el.offsetTop-el.scrollTop;
            el = el.offsetParent;
            el2 = el2.parentNode;
            while (el2 != el) {
                curleft -= el2.scrollLeft;
                curtop -= el2.scrollTop;
                el2 = el2.parentNode;
            }
        } while (el.offsetParent);

    } 
    return [curtop, curleft];
}


}
