import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';


import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';

import { mergeMap, take, catchError, map } from 'rxjs/operators';

import { SettingService } from '../setting.service';
import { ProductplanService } from '../productplan.service';
import { ServiceproviderService } from '../serviceprovider.service';

@Injectable({
    providedIn: 'root',
})
export class Serviceproviderform implements Resolve<any>  {
    constructor(private router: Router, private ss: SettingService, private pp: ProductplanService, private ps: ServiceproviderService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        var States = this.ss.getStates();
        var company = this.ps.getProviderCompanies();
        var group = this.ps.getProviderGroup();
        var Plan = this.pp.getProviderPlan("2");
        return forkJoin(States, Plan, company, group);
    }
}
