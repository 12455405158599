import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbtnComponent } from './navbtn.component';
import { NavbtnRoutingModule } from './navbtn-routing.module';
import { LaddaModule } from 'angular2-ladda';
import { ToasterModule, ToasterService} from 'angular2-toaster/angular2-toaster';
import { HreffilterPipe } from './hreffilter.pipe';
@NgModule({
  declarations: [NavbtnComponent, HreffilterPipe],
  imports: [
    CommonModule,NavbtnRoutingModule,LaddaModule,ToasterModule
  ], exports: [NavbtnComponent]
})
export class NavbtnModule { }
