 import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IndeterminateService } from './indeterminate.service';
@Component({
  selector: 'app-indeterminate',
  templateUrl: './indeterminate.component.html',
  styleUrls: ['./indeterminate.component.scss']
})
export class IndeterminateComponent implements OnInit {

 subscription: Subscription;
 isIndeterminate:boolean;
  constructor(private indS: IndeterminateService) { }

  ngOnInit() {
  this.isIndeterminate=false;
  this.subscription=this.indS.onShowInderminate().subscribe(Isshow=>{
  
    this.isIndeterminate=Isshow.show;
  });
  }

}
