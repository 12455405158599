import { Injectable,Output, EventEmitter } from '@angular/core';
import { Popover } from './pover';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PoverService {
private subject = new Subject<Popover>();
  constructor() { }
    onPopOver():Observable<Popover>{
       return this.subject;
  }
    
    confirm(options){
   
  this.subject.next(new Popover(options));
    }
}
