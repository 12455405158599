import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthService } from './services';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet><app-pover></app-pover>'
})
export class AppComponent implements OnInit {
  isInitLogout;
  constructor(private router: Router,private token: AuthService) { }

  ngOnInit() {
    this.checkifLogedout();
  
         
    this.router.events.subscribe((evt) => {
     
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      
        
        (evt instanceof NavigationStart);
      window.scrollTo(0, 0);
    });
  }

  checkifLogedout(){
if(this.isInitLogout){
clearTimeout(this.isInitLogout);
}
const Userdata = this.token.AdminUserValue;
if(Userdata!=null){
  const jwtToken = JSON.parse(atob(Userdata.token.split('.')[1]));
 
  if( Date.now() > jwtToken.exp*1000) {
    this.token.logout();
     }  
     this.isInitLogout=setTimeout(()=>{
      this.checkifLogedout();
      },150);
};
 

  }
}
