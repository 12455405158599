import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoverComponent } from './pover.component';



@NgModule({
  declarations: [PoverComponent],
  imports: [
    CommonModule
  ], exports: [PoverComponent],
     schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PoverModule { }
