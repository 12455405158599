export class Popover {
isconfirm:boolean;

    title:string;
    content:string;
    elem:Element;
 isShow:boolean;
    yescallback:Function;
    nocallback:Function;

constructor(init?:Partial<Popover>) {
    
        Object.assign(this, init);
    }
}