import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Indeterminate } from './preloaders';

@Injectable({
  providedIn: 'root'
})
export class IndeterminateService {

 private subject = new Subject<Indeterminate>();
  constructor() { }
  onShowInderminate():Observable<Indeterminate>{
       return this.subject;
  }
  showInfinite(){

  this.subject.next(new Indeterminate({show:true}));
  }
  hideInfinite(){
 
  this.subject.next(new Indeterminate({show:false}));
  }
}
