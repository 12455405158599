import { Injectable, Output, EventEmitter } from '@angular/core';
import { NavBtn } from './navbtn';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, Resolve, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable, BehaviorSubject, of, concat, from, Subject } from 'rxjs';
import { filter, mergeMap, distinct, toArray, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavbtnService {
  private subject = new Subject<NavBtn>();
  @Output() NavBtnEvent = new EventEmitter();
  constructor(private router: Router, route: ActivatedRoute) {
    this.router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {

      let child = route.firstChild;
      var usermenu = JSON.parse(localStorage.getItem("links"));

      var rt = this.getChild(route);


      rt.data.subscribe(data => {

        var btnlist = {};
        btnlist["btn"] = [];
        if (data.btn) {
          

          for (var bin in data.btn) {
            data.btn[bin]["clicked"] = false;
            //  btnlist["btn"].push(data.btn[bin]);
            if (data.btn[bin].click) {
              btnlist["btn"].push(data.btn[bin]);
            } else {

              var isaccess = usermenu.filter(x => x.url.includes(data.btn[bin].href));
              if (isaccess.length != 0) {

                btnlist["btn"].push(data.btn[bin]);
              };
            }


          }


          this.addbtn(btnlist);
        } else {
          btnlist["btn"] = [];
          this.addbtn(btnlist["btn"]);
        };

      });


    });

  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
  onAdd(): Observable<NavBtn> {
    return this.subject;
  }
  addbtn(btnobj: {}) {
    setTimeout(() => {
      this.navbars(new NavBtn(btnobj));
    }, 50);



  }

  emptyBtn() {
    this.subject = new Subject<NavBtn>();
  }

  navbars(navbar: NavBtn) {

    this.subject.next(navbar);
  }
  callbackclick(event, btn) {

    this.NavBtnEvent.emit({ ev: event, btn: btn })
  }
  removeevent() {
    this.NavBtnEvent = new EventEmitter();
  }
}
