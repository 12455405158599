import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from "rxjs/operators";

import { AuthService } from '../services';
import { IndeterminateService } from '../ui';

const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private token: AuthService, private loader: IndeterminateService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = request;
    const skipIntercept = request.headers.has('skip');
    if (!skipIntercept) {

      const Userdata = this.token.AdminUserValue;
      this.loader.showInfinite();
    
      if (this.getLocation(authReq.url).hostname == "api.alicorn.co") {
        if (Userdata) {
          const token = Userdata;
          const jwtToken = JSON.parse(atob(Userdata.token.split('.')[1]));
          if (Date.now() > jwtToken.exp * 1000) {
            this.token.logout();
          } else {
            authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token.token) });

          }
        }
        
      }
      
      return next.handle(authReq).pipe(
        finalize(() => this.loader.hideInfinite())
      );

      
    } else {
      if (skipIntercept) {
        request = request.clone({
            headers: request.headers.delete('skip')
        });
    }
    this.loader.showInfinite();
      return next.handle(request).pipe(finalize(()=>this.loader.hideInfinite()));
    }
  }
  getLocation(href) {
    var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && {
      href: href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7]
    }
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
