import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';


import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';

import { mergeMap, take, catchError, map } from 'rxjs/operators';

import { SettingService } from '../setting.service';
import { ProductplanService } from '../productplan.service';
import {CustomersService} from '../customers.service';

@Injectable({
    providedIn: 'root',
})
export class ProviderCutomerProduct implements Resolve<any> {
    constructor(private router: Router, private ss: SettingService, private pp: ProductplanService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        var Providers = this.ss.getProviders();
        var productList = this.pp.getProducts("1",null);
        var planList=this.pp.getPlan("1");


        return forkJoin(Providers, productList,planList);
    }
}
