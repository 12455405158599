import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  apiurl = AppConfigService.settings.apiServer.apiurl;
  aliapiurl=AppConfigService.settings.apiServer.aliapiurl;

  constructor(private http: HttpClient) { }

  getCustomer(usertype: string, filter: object): Observable<any[]> {

    let url = this.apiurl + "customers/GetFilteredCustomers";
    return this.http.post<any[]>(url, filter);
  }
  getCustomerDuplicate(usertype: string, filter: object): Observable<any[]> {

    let url = this.apiurl + "customers/GetFilteredDuplicateCustomers";
    return this.http.post<any[]>(url, filter);
  }
  getCustomerbysponsor(usertype: string, filter: object): Observable<any[]> {

    let url = this.apiurl + "customers/GetFilteredCustomersbySponsor";
    return this.http.post<any[]>(url, filter);
  }
  getSampleCustomer(): Observable<any[]> {

    let url =  "https://carechat.alicorn.co/api/v-sync/customers";
    return this.http.get<any[]>(url);
  }
  getProviderCustomer(usertype: string, filter: object): Observable<any[]> {
     let url = this.apiurl + "customers/GetFilteredDPCCustomers ";
   //let url=this.aliapiurl+"provider/getmembergrid";
    return this.http.post<any[]>(url, filter);
  }
  getDPCMember(providerKey,search): Observable<any[]>{
let url=this.aliapiurl+"customer/getdpcmemberprovider?ppk="+providerKey+"&s="+search;
const headers = { 'skip': 'true' };
  return this.http.get<any[]>(url,{headers});
}
  getAutoWelcomeCustomer(): Observable<any[]> {

    let url = this.apiurl + "customers/GetAutoWelcomeCustomers";
    return this.http.post<any[]>(url,{});
  }

  getCustomerMapping(usertype: string, filter: object): Observable<any[]> {

    let url = this.apiurl + "customers/GetFilteredCustomerIds";
    return this.http.post<any[]>(url, filter);
  }

  getCustomerPk(pk): Observable<any[]> {
    let url = this.apiurl + "customers/"+pk;
    return this.http.get<any[]>(url);
   /* return this.http.post<any[]>(url, { "PrimaryKey": pk, "SecondaryKey": "0", "ThirdKey": "0", "FourthKey": "0", "FifthKey": "0", "SearchText": "", "SortOrder": "", "SortExpression": "", "FromDate": "", "ToDate": "", "IsPaging": 0, "PageSize": 30, "PageNumber": 1 });*/
  }


  getCustomerDetailsPk(pk): Observable<any[]> {
    let url = this.apiurl + "customers/GetFilteredCustomers";
    return this.http.post<any[]>(url, { "PrimaryKey": pk, "SecondaryKey": "0", "ThirdKey": "0", "FourthKey": "0", "FifthKey": "0", "SearchText": "", "SortOrder": "", "SortExpression": "", "FromDate": "", "ToDate": "", "IsPaging": 0, "PageSize": 30, "PageNumber": 1 });
  }
  getProviderProduts() {
    let url = this.apiurl + "CustomerProviders";
    return this.http.get<any[]>(url);
  }
}
