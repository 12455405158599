import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfigService } from '../../../services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { SettingService } from '../../../services';
@Component({
  selector: 'app-dashboard-drill-data',
  templateUrl: './dashboard-drill-data.component.html',
  styleUrls: ['./dashboard-drill-data.component.css']
})
export class DashboardDrillDataComponent implements OnInit {
  public list:Array<any>=[];
  public column:Array<any>=[];
  public PageTitle:String="";

  constructor(private route: ActivatedRoute,private router:Router, private http: HttpClient,private ss:SettingService) { }

  ngOnInit(): void {
     
    var querypara=this.route.snapshot.queryParams;
      var str = [];
      var queryKey=Object.keys(querypara);
    for (var p in queryKey){
      if(queryKey[p]!="type"){
        str.push(encodeURIComponent(queryKey[p]) + "=" + encodeURIComponent(querypara[queryKey[p]]));
      }
      
    }
    var quertString=str.join("&") ;
    if(querypara["type"]=='activemember' || querypara["type"]=='activeadult'||querypara["type"]=='activeadultemail' ||querypara["type"]=='activeadulelogged' ){
      if(querypara["type"]=='activemember'){
        this.PageTitle="Total number of active members";
      }else if(querypara["type"]=='activeadult'){
        this.PageTitle="Total number of active adult members";
      }else if(querypara["type"]=='activeadultemail'){
        this.PageTitle="Total number of active adult members with email";
      }else if(querypara["type"]=='activeadulelogged'){
        this.PageTitle="Total number of active adult members logged in";

      }
      this.loadcount(quertString);
    }else if(querypara["type"]=='visit'){
      this.PageTitle="Visit History  ("+querypara["drillname"]+")";
this.loadvisithistory(quertString);
    }else if(querypara["type"]=='email'){
      this.PageTitle="Email History ("+querypara["drillname"]+")";
      this.loademailsummary(quertString);
          }
          else if(querypara["type"]=='sms'){
            this.PageTitle="SMS History ("+querypara["drillname"]+")";
            this.loadsmssummary(quertString);
                }
                else if(querypara["type"]=='visitdetails'){
                  this.PageTitle="Unique Visit Summary ("+querypara["drillname"]+")";
                  this.loadvisitdetailsummary(quertString);
                      }
    
  }
  
  loadcount(query){
    var syncurlCount = AppConfigService.settings.apiServer.aliapiurl + "admin/dpcount?"+query;
      this.http.get<any>(syncurlCount).subscribe((data) => {  
    this.list=data;
    if(data.length!=0){
      var obKey=Object.keys(data[0]); 
this.column=obKey ; 

    }
      })
  }
  loadvisithistory(query){ 
    this.ss.getVisitSummary(query).subscribe((data)=>{
    
      this.list=data;
      if(data.length!=0){
        var obKey=Object.keys(data[0]); 
  this.column=obKey ;
  
      }
    });
  
  }
  loademailsummary(query){
    this.ss.getEmailSummary(query).subscribe((data)=>{ 
    
      this.list=data;
      if(data.length!=0){
        var obKey=Object.keys(data[0]); 
  this.column=obKey ;
  
      }
     });
    
  }
  loadsmssummary(query){
    this.ss.getSMSummary(query).subscribe((data)=>{ 
      this.list=data;
      if(data.length!=0){
        var obKey=Object.keys(data[0]); 
  this.column=obKey;
  
      }
     });
    
  }

  loadvisitdetailsummary(query){
    this.ss.getvistdetailSummary(query).subscribe((data)=>{ 
      this.list=data;
      if(data.length!=0){
        var obKey=Object.keys(data[0]); 
  this.column=obKey;
  
      }
     });
    
  }

}
