import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './helper/auth.guard';
// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/auth/login/login.component';
import { RegisterComponent } from './views/auth/register/register.component';
import { Initdata } from './services';
import { ForgotpasswordComponent } from './views/auth/forgotpassword/forgotpassword.component';
import { PasswordresetComponent } from './views/auth/passwordreset/passwordreset.component';

import { DashboardDrillDataComponent } from './views/dashboard/dashboard-drill-data/dashboard-drill-data.component';
import { DBViewComponent } from './views/dashboard/dbview/dbview.component';
import { Customerform } from './services/resolve/customerform'; 
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'resetpassword/:token',
    component: PasswordresetComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'identify',
    component: ForgotpasswordComponent,
    data: {
      title: 'Forgot Password Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    }, resolve: {
      init: Initdata

    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard', component: DBViewComponent,
        data: {
          title: 'Dashboard'
         
        } ,resolve: {
          init: Customerform
    
           }
      },
      {
        path: 'drilldata', component: DashboardDrillDataComponent,
        data: {
          title: 'Drill Down Data'
         
        }
      },
      {
        path: 'members',
        loadChildren: () => import('./views/customers/customers.module').then(m => m.CustomersModule)
      }, {
        path: 'serviceprovider',
        loadChildren: () => import('./views/serviceprovider/serviceprovider.module').then(m => m.ServiceproviderModule)
      }, {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule)
      }, {
        path: 'order',
        loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule)
      }, {
        path: 'doctors',
        loadChildren: () => import('./views/doctors/doctors.module').then(m => m.DoctorsModule)
      }, {
        path: 'email',
        loadChildren: () => import('./views/email/email.module').then(m => m.EmailModule)
      }, {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
      }, {
        path: 'sponsor',
        loadChildren: () => import('./views/sponsors/sponsors.module').then(m => m.SponsorsModule)
      }, {
        path: 'pp',
        loadChildren: () => import('./views/productplan/productplan.module').then(m => m.ProductplanModule)
      }, {
        path: 'sppp',
        loadChildren: () => import('./views/productplan/productplan.module').then(m => m.ProductplanModule)
      }, {
        path: 'sl',
        loadChildren: () => import('./views/servicel/servicel.module').then(m => m.ServicelModule)
      }, {
        path: 'enquiries',
        loadChildren: () => import('./views/enquiries/enquiries.module').then(m => m.EnquiriesModule)
      }, {
        path: 'birdie',
        loadChildren: () => import('./views/birdie/birdie.module').then(m => m.BirdieModule)
      }, {
        path: 'direct',
        loadChildren: () => import('./views/direct/direct.module').then(m => m.DirectModule)
      },
      {
        path: 'copilot',
        loadChildren: () => import('./views/copilot/copilot.module').then(m=>m.CopilotModule)
      },
      {
        path: 'product-dependencies',
        loadChildren: () => import('./views/applications/applications.module').then(m => m.ApplicationsModule)
      }, 
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
