
<div  class=" w-100  d-flex  h-100 ">
    <div  class="d-flex w-100  d-flex  flex-row justify-content-end align-items-center px-3 " >
        <div *ngFor="let list of navbts">
 <div  *ngIf="list.btn && list.btn!=0">
<a [routerLink]="(btn.href!='')?[btn | hreffilter]:[]" *ngFor="let btn of list.btn" (click)="btn.click && eventControl($event,btn)"  [ladda]="btn.clicked" [disabled]="btn.clicked"  [id]="btn.id"  [class]="'font-weight-bold btn btn-sm border mr-2 '"><i *ngIf="btn.icon && btn.icon!=''" [class]="'icons '+btn.icon "></i> {{btn.text}}</a>

</div>
            </div>
        </div>
    
</div>

<toaster-container [toasterconfig]="toasterconfig"></toaster-container>