import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';


import { BehaviorSubject,Observable, of, forkJoin}  from 'rxjs';

import { mergeMap, take,catchError,map }         from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class Initdata implements Resolve<any> {
     constructor(private router: Router, private admin:AuthService) {  }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

     var menus=this.admin.usermenu();
        
            return forkJoin(menus);
        }
}
