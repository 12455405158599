import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndeterminateComponent } from './indeterminate.component';



@NgModule({
  declarations: [IndeterminateComponent],
  imports: [
    CommonModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
   exports: [ IndeterminateComponent ],
})
export class IndeterminateModule { }
