import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class ProductplanService {
  apiurl = AppConfigService.settings.apiServer.apiurl;
  papiurl=AppConfigService.settings.apiServer.aliapiurl;
  constructor(private http: HttpClient) { }

  getPlan(iscarechat): Observable<any[]> {
    let url = this.apiurl + ((iscarechat == "1") ? "plans" : "plans/GetProviderPlans");

    return this.http.get<any[]>(url);
  }
  getProviderPlan(iscarechat): Observable<any[]> {
    let url = this.apiurl + "plans/GetProviderPlans";
    return this.http.get<any[]>(url);
  }
  getInsuranceList(): Observable<any[]> {
    let url = this.papiurl + "doctor/getinsurancenetwork";
    return this.http.get<any[]>(url);
  }
  getNewPlan(iscarechat,isAutoWelcome,isMarketingEmail,isAutoSMS,isHideInsurance): Observable<any[]> {
    //let url = this.papiurl + "plan/grid";
    
    let url =  ((iscarechat == "1") ? this.papiurl + "plan/grid?isAutoWelcome="+isAutoWelcome+"&isMarketingEmail="+isMarketingEmail+"&isAutoSMS="+isAutoSMS+"&IsHideInsuranceNetwork="+isHideInsurance : this.papiurl +"plan/GetProviderPlans");
    return this.http.get<any[]>(url);
  }
  getPlanFaq(plan): Observable<any[]> {
    let url = this.papiurl + "plan/fetchplanfaq"+((plan!="")?"?plan="+plan:"");
    return this.http.get<any[]>(url);
  }

  getSinglePlanFaq(pk): Observable<any[]> {
    let url = this.papiurl + "plan/fetchplanfaq"+((pk!="")?"?pk="+pk:"");
    return this.http.get<any[]>(url);
  }

  getPlanPk(pk) {
    let url = this.apiurl + "plans/" + pk;
    return this.http.get<any[]>(url);
  }
  getPlanIdCardInfo(): Observable<any[]> {
    let url = this.papiurl + "plan/fetchplanidinfo";
    return this.http.get<any[]>(url);
  }
  getSinglePlanIdCardInfo(pk): Observable<any[]> {
    let url = this.papiurl + "plan/fetchplanidinfo?pk="+pk;
    return this.http.get<any[]>(url);
  }

  getProducts(iscarechat,isArchived): Observable<any[]> {
    let url = this.apiurl + "products" + ((iscarechat == "1") ? "" : "/GetProviderProducts");
    if (isArchived&&isArchived=="1") {
      url = this.apiurl + "products/GetArchiveProducts";
    }

    return this.http.get<any[]>(url);
  }
  getPlanProductsList(iscarechat,plankey): Observable<any[]> {
    
    let url = this.apiurl +((iscarechat=="1")? "products/GetCustomerProductsByPlan":"products/GetProviderProductsByPlan");
    

    let retorno = this.http.post<any[]>(url,  {"SecondaryKey":plankey});

    return retorno;
  }
  getPlanChildProductsList(iscarechat,plankey): Observable<any[]> {
    
    let url = this.apiurl +((iscarechat=="1")? "products/GetCustomerChildProductsByPlan":"products/GetProviderChildProductsByPlan");
    

    let retorno = this.http.post<any[]>(url,  {"SecondaryKey":plankey});

    return retorno;
  }

  getProductsPk(pk) {
    let url = this.apiurl + "products/" + pk;
    return this.http.get<any[]>(url);
  }

  getProductsPlan(iscarechat,params:object): Observable<any[]> {
    const searchParams = new URLSearchParams();
    
    Object.keys(params).forEach(key => searchParams.append(key, params[key]));
   var queryString=searchParams.toString();
   var endpoint=(iscarechat == "1") ? "memberproductplan" : "providerproductplan";
   // let url = this.apiurl + "planproducts" + ((iscarechat == "2") ? "/GetProviderPlanProducts" : "");
   let url=this.papiurl+"admin/"+endpoint+((queryString)?"?"+queryString:"");
    

    return this.http.get<any[]>(url);
  }

  getProductsPlanPk(pk): Observable<any[]> {
    let url = this.apiurl + "planproducts/" + pk;

    return this.http.get<any[]>(url);
  }

  getPlanInsurance(iscarechat,params:object): Observable<any[]> {
    const searchParams = new URLSearchParams();
    
    Object.keys(params).forEach(key => searchParams.append(key, params[key]));
   var queryString=searchParams.toString();
   var endpoint=(iscarechat == "1") ? "memberplaninsurance" : "providerplaninsurance";
   // let url = this.apiurl + "planproducts" + ((iscarechat == "2") ? "/GetProviderPlanProducts" : "");
   let url=this.papiurl+"admin/"+endpoint+((queryString)?"?"+queryString:"");
    

    return this.http.get<any[]>(url);
  }
}
