import { Component, OnInit } from '@angular/core';
//import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../../services/app-config.service';
import { NavbtnService } from '../../../ui';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingService } from '../../../services';
@Component({
  selector: 'app-dbview',
  templateUrl: './dbview.component.html',
  styleUrls: ['./dbview.component.css']
})
export class DBViewComponent implements OnInit {

 

  public column: Array<any> = [];
  public list: Array<any> = [];
  public fullList: Array<any> = [];
  public LeadSourcelist: Array<any> = [];
  public Planlist: Array<any> = [];
  public Sponsorslist: Array<any> = [];
  public Providerslist: Array<any> = [];
  public recordList: Array<any> = [30, 50, 100, 300, 500, 'All'];
  public DivisionList:Array<any>=[];
  public DiviMasterList:Array<any>=[];
public DivisionFilterList:Array<any>=[];
public  visitlist:Array<any>=[];
public  visitdetaillist:Array<any>=[];


public emailsummarylist:Array<any>=[];
public smsummarylist:Array<any>=[];
public visitcolumn:Array<any>=[];
public visitdetailcolumn:Array<any>=[];
public emailcolumn:Array<any>=[];
public smscolumn:Array<any>=[];

  searchText: string = "";
  LeadSourceKey: string = "0";
  PlanKey: string = "0";
  SponsorsKey: string = "0";
  ProvidersKey: string = "0";
  DivisionKey:String="0";

  EmailMstartValue:String="";
  EmailEstartValue:String="";
  sDate:String="";
  eDate:String="";
  EmailMValue:String="";
  EmailEValue:String="";
  daterangepickerModel?: Date[];
  constructor(private navbtn: NavbtnService, private http: HttpClient,private route: ActivatedRoute,private ss:SettingService,private router:Router) { }

  ngOnInit(): void {
    this.navbtn.NavBtnEvent.subscribe((event) => {
      if (event.btn.id == "hintsyncplan") {
        event.btn.clicked = true;
        var syncurl = AppConfigService.settings.apiServer.aliapiurl + "hint/syncplans";
        this.http.get<any>(syncurl).subscribe((data) => {
          event.btn.clicked = false;
        })
      } else if (event.btn.id == "hintsynMember") {
        event.btn.clicked = true;
        var syncurl = AppConfigService.settings.apiServer.aliapiurl + "hint/syncmemberships";
        this.http.get<any>(syncurl).subscribe((data) => {
          event.btn.clicked = false;
        })
      } else if (event.btn.id == "hintsynCom") {
        event.btn.clicked = true;
        var syncurl = AppConfigService.settings.apiServer.aliapiurl + "hint/synccompanies";
        this.http.get<any>(syncurl).subscribe((data) => {
          event.btn.clicked = false;
        })
      } else if (event.btn.id == "hintsynLS") {
        event.btn.clicked = true;
        var syncurl = AppConfigService.settings.apiServer.aliapiurl + "hint/syncleadsource";
        this.http.get<any>(syncurl).subscribe((data) => {
          event.btn.clicked = false;
        })
      }

    });

    var initdata = this.route.snapshot.data['init'];
    this.Planlist = initdata[2].filter(function(item){ 
      return item['name']!=null 
    }).sort( function(a, b) {
      var nameA = a.name.toUpperCase(); 
      
      var nameB = b.name.toUpperCase();  
      if (nameA < nameB) {
        return -1;  
      }
      if (nameA > nameB) {
        return 1;  
      }
      return 0;   
    });
    this.Sponsorslist = initdata[3].sort( function(a, b) {
      var nameA = a.Name.toUpperCase(); 
      
      var nameB = b.Name.toUpperCase();  
      if (nameA < nameB) {
        return -1;  
      }
      if (nameA > nameB) {
        return 1;  
      }
      return 0;   
    });
    this.Providerslist = initdata[4];
    this.DivisionList=initdata[5];
    this.DiviMasterList=initdata[5];
    this.DivisionFilterList=initdata[5];

    this.loadcount();
    this.loadvisithistory();
    this.loademailsummary();
    this.loadsmssummary();
    this.loadvisitdetailsummary();

  }

  filterdb(event){
    this.loadcount();
    this.loadvisithistory();
    this.loademailsummary();
    this.loadsmssummary();
    this.loadvisitdetailsummary();
  }

  onDateValueChange(value: Date){
    if(value && value[0]){
  var sdatInx:Date=value[0];
  var firstDate=(sdatInx.getFullYear()+'-'+((sdatInx.getMonth()+1).toString().padStart(2,'0'))+'-'+(sdatInx.getDate().toString().padStart(2,'0'))).toString() ;
  var EdatInx:Date=value[1];
  var SecondDate=EdatInx.getFullYear()+'-'+((EdatInx.getMonth()+1).toString().padStart(2,'0'))+'-'+(EdatInx.getDate().toString().padStart(2,'0')) ;
  this.sDate=firstDate;
  this.eDate=SecondDate;
 /* this.filter["FromDate"] = this.sDate;
  this.filter["ToDate"] = this.eDate;*/
  }else{
    this.sDate="";
    this.eDate="";
  /*this.filter["FromDate"] = "";
  this.filter["ToDate"] = "";*/
  }
  }
   jsonToQueryString(json) {
    return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
}
  drilldown(event,drillname,type){
event.preventDefault();
var filter=this.jsonToQueryString({drillname:drillname, plan: this.PlanKey,sponsor:this.SponsorsKey,division:this.DivisionKey,sdate:this.sDate,edate:this.eDate,"type":type });
const url = this.router.serializeUrl(
  this.router.createUrlTree([`/drilldata`])
);

window.open("#/"+url+""+""+filter, '_blank');
/*this.router.navigate(['/dashboard/drilldata',drillname], {  queryParams: {  plan: this.PlanKey,sponsor:this.SponsorsKey,division:this.DivisionKey,sdate:this.sDate,edate:this.eDate,"type":type } });*/
  }
   
loadcount(){
  var syncurlCount = AppConfigService.settings.apiServer.aliapiurl + "admin/dpcount?plan="+this.PlanKey+"&sponsor="+this.SponsorsKey+"&division="+this.DivisionKey+"&sdate="+this.sDate+"&edate="+this.eDate;
    this.http.get<any>(syncurlCount).subscribe((data) => { 
 
      var columNames=Object.keys(data);
      columNames.forEach((clname)=>{
        console.log(document.getElementById(clname));
if(document.getElementById(clname)){
  document.getElementById(clname).textContent=(data[clname]==null)?0:data[clname];
}
      })

    
    })
}
loadvisithistory(){ 
  this.ss.getVisitSummary("plan="+this.PlanKey+"&sponsor="+this.SponsorsKey+"&division="+this.DivisionKey+"&sdate="+this.sDate+"&edate="+this.eDate).subscribe((data)=>{
    if(data.length!=0){
      var obKey=Object.keys(data[0]); 
       this.visitcolumn=obKey;
   this.visitlist=data;
   }else{
    this.visitlist=[];
   }
  });

}
loademailsummary(){
  this.ss.getEmailSummary("plan="+this.PlanKey+"&sponsor="+this.SponsorsKey+"&division="+this.DivisionKey+"&sdate="+this.sDate+"&edate="+this.eDate).subscribe((data)=>{ 
    if(data.length!=0){
      var obKey=Object.keys(data[0]); 
       this.emailcolumn=obKey;
   this.emailsummarylist=data;
   }else{
    this.emailsummarylist=[];
   }
   });
  
}
loadsmssummary(){
  this.ss.getSMSummary("plan="+this.PlanKey+"&sponsor="+this.SponsorsKey+"&division="+this.DivisionKey+"&sdate="+this.sDate+"&edate="+this.eDate).subscribe((data)=>{ 
    if(data.length!=0){
      var obKey=Object.keys(data[0]); 
       this.smscolumn=obKey;
   this.smsummarylist=data;
   }else{
    this.smsummarylist=[];
   }
   });
  
}

loadvisitdetailsummary(){
  this.ss.getvistdetailSummary("plan="+this.PlanKey+"&sponsor="+this.SponsorsKey+"&division="+this.DivisionKey+"&sdate="+this.sDate+"&edate="+this.eDate).subscribe((data)=>{ 
    if(data.length!=0){
      var obKey=Object.keys(data[0]); 
       this.visitdetailcolumn=obKey;
   this.visitdetaillist=data;
   }else{
    this.visitdetaillist=[];
   }
   });
  
}

MStartChanged(event){ 
 
  if(event!=null){
    var newdateF = [`${event.getFullYear()}`,`0${event.getMonth() + 1}`.substr(-2), `0${event.getDate()}`.substr(-2), ].join("-");
 
    this.EmailMValue=newdateF;
  }else{
    this.EmailMValue="";
  }

 
   
}
EStartChanged(event){ 
  if(event!=null){
    var newdateF = [`${event.getFullYear()}`,`0${event.getMonth() + 1}`.substr(-2), `0${event.getDate()}`.substr(-2), ].join("-");
this.EmailEValue=newdateF;
  }else{
    this.EmailEValue="";
  }

}
filtergrid(){
  this.loademailsummary();
}
}
