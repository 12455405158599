import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';


import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';

import { mergeMap, take, catchError, map } from 'rxjs/operators';

import { SettingService } from '../setting.service';
import { ProductplanService } from '../productplan.service';
import { ServiceproviderService } from '../serviceprovider.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerDPC implements Resolve<any> {
  constructor(private router: Router, private ss: SettingService, private pp: ProductplanService,private ps:ServiceproviderService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var LeadSource = this.ss.getLeadSource();
    var Plan = this.pp.getPlan("1");
    var Sponsors = this.ss.getSponsors('0');
    var Providers = this.ps.getServiceProver({});
    var dpcprovider=this.ps.getdpcProvider({});
    var vpcprovider=this.ps.getvpcProvider({});
    var vcprovider=this.ps.getvcProvider({});
    var States = this.ss.getStates();
    var division=this.ss.getDivision();

    return forkJoin(States, LeadSource, Plan, Sponsors, Providers,dpcprovider,division,vpcprovider,vcprovider);
  }
}
