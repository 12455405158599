import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';

import { BehaviorSubject, Observable, of, EMPTY, from, Subject } from 'rxjs';

import { mergeMap, take, catchError, map } from 'rxjs/operators';

import { ProductplanService } from '../productplan.service'

@Injectable({
    providedIn: 'root',
})

export class Plandetail implements Resolve<any> {
    constructor(private router: Router, private pp: ProductplanService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let customerid = route.paramMap.get('id');
        return this.pp.getPlanPk(customerid);
    }
}
