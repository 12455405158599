import { Pipe, PipeTransform } from '@angular/core';
import {Event, Router, ActivatedRoute} from '@angular/router';
@Pipe({
  name: 'hreffilter'
})
export class HreffilterPipe implements PipeTransform {
 constructor(public router: Router, private route: ActivatedRoute) { }
  transform(value: unknown, ...args: unknown[]): unknown {
     var href=value["href"];
      if(value.hasOwnProperty("querypara")){
          var paramter=this.getChild(this.route).params.value;
          var querylist="";
          if(paramter.hasOwnProperty(value["querypara"])){
             var parameterVal=paramter[value["querypara"]];
             querylist=parameterVal;
             }
          href+="/"+querylist
       
         }
    return href;
  }
    
     getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }

}
