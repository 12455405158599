import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule }  from '@angular/common';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterModule, ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css','../../../../scss/vendors/toastr/toastr.scss','../../../../../node_modules/ladda/dist/ladda-themeless.min.css'],
  encapsulation: ViewEncapsulation.None,host: {'class': 'd-flex flex-column app-forgotpassword'},
  providers: [ToasterService]
})
export class ForgotpasswordComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
     submitted = false;
     returnUrl: string;
     error = '';
     currentyear: number = new Date().getFullYear();
     private toasterService: ToasterService;
     public toasterconfig: ToasterConfig =
       new ToasterConfig({
         tapToDismiss: true,
         timeout: 5000
       });
     constructor(private authS: AuthService,private formBuilder: FormBuilder,private route: ActivatedRoute,
      private router: Router,toasterService: ToasterService){this.toasterService = toasterService; }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]], 
  });
  //this.toasterService.pop('success', 'Success', 'Password rest link have been emailed to '+this.f.username.value);
  }
  get f() { return this.loginForm.controls; }
  onSubmit() {
    let self=this;
    this.submitted = true;
    if (this.loginForm.invalid) { 
               return;
           }
           this.loading = true;
           this.authS.forgotpassword(this.f.username.value).subscribe(data=>{
            this.loading = false;
            this.submitted=false;
            if(data["status"]!=''){
 this.toasterService.pop('success', 'Success', 'Password rest link have been emailed to '+this.f.username.value);
            }else{
               this.toasterService.pop('error', 'Error', this.f.username.value+' is not registered.');
            }
           
           });

  }

}
