import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY } from 'rxjs';

@Pipe({
  name: 'optionfilter'
})
export class OptionfilterPipe implements PipeTransform {

  transform(items: any[], filter: object): unknown {
    if (!items || !filter) {
      return items;
  }
 
 if(String(filter["mapval"])=="" || String(filter["mapval"])=="0"){
  return items;
 }
  return items.filter(item=>{
 var keyVal=String(item[filter["mapkey"]]);
     return keyVal.toLowerCase()==String(filter["mapval"]).toLowerCase();
  } );
  }

}
