import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, Location, PathLocationStrategy } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Routes, RouterModule } from '@angular/router';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppConfigService } from './services/app-config.service';

import { AppComponent } from './app.component';
import { LaddaModule } from 'angular2-ladda';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/auth/login/login.component';
import { RegisterComponent } from './views/auth/register/register.component';
import { NavbtnModule, IndeterminateModule, PoverModule } from './ui';
const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { authInterceptorProviders } from './helper/auth.interceptor';
import { ForgotpasswordComponent } from './views/auth/forgotpassword/forgotpassword.component';
import { PasswordresetComponent } from './views/auth/passwordreset/passwordreset.component';
import { ToasterModule, ToasterService } from 'angular2-toaster/angular2-toaster';
import { OptionfilterPipe } from './views/dashboard//filter/optionfilter.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; 
import { DashboardDrillDataComponent } from './views/dashboard/dashboard-drill-data/dashboard-drill-data.component';
import { DBViewComponent } from './views/dashboard/dbview/dbview.component';
import { AceEditorDirective } from 'ng2-ace-editor';
export function initializeApp(appConfigService: AppConfigService) {
  return (): Promise<any> => {
    return appConfigService.load();
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    DatepickerModule.forRoot(),
    ChartsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    HttpClientModule,
    LaddaModule,
    NavbtnModule,
    IndeterminateModule,
    PoverModule,
    ToasterModule, BsDatepickerModule.forRoot()
    
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    ForgotpasswordComponent,
    PasswordresetComponent,
    DashboardDrillDataComponent,
    DBViewComponent,OptionfilterPipe
  ],

  providers: [authInterceptorProviders, AppConfigService,{ provide: LocationStrategy, useClass: HashLocationStrategy },  { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
//{ provide: LocationStrategy, useClass: HashLocationStrategy }, 
