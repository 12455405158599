import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class EmailService {
  apiurl = AppConfigService.settings.apiServer.apiurl;

  constructor(private http: HttpClient) { }
  getEmailTypes(): Observable<any[]> {
    let url = this.apiurl + "EmailTypes";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }

  getEmailSent(): Observable<any[]> {
    let url = this.apiurl + "EmailSents";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response.filter(row => {
          row["isExpanded"] = true;

          return row;
        });
      })
    );;
  }

  getUserEmailSent(pk: string): Observable<any> {
    if (pk) {
      let url = this.apiurl + "EmailSents/CustomerEmails?customerkey=" + pk;
      return this.http.get<any>(url);
    } else {
      return null;
    }


  }
}
