import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  apiurl = AppConfigService.settings.apiServer.apiurl;
  sapiurl = AppConfigService.settings.apiServer.aliapiurl;

  constructor(private http: HttpClient) { }
  getOrders(): Observable<any[]> {
    let url = this.apiurl + "Orders";

    return this.http.get<any[]>(url).pipe(
      map((response: any) => {

        return response;
      })
    );;
  }


  getOrderDetails(pk: string): Observable<any> {
    if (pk) {
      let url = this.sapiurl + "order/single?pk=" + pk;
      return this.http.get<any>(url);
    } else {
      return null;
    }


  }

  getUserOrders(pk: string): Observable<any> {
    if (pk) {
      let url = this.apiurl + "Orders/CustomerOrders?customerkey=" + pk;
      return this.http.get<any>(url);
    } else {
      return null;
    }


  }

}
