import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';


import { BehaviorSubject,Observable, of, forkJoin}  from 'rxjs';

import { mergeMap, take,catchError,map }         from 'rxjs/operators';

import { AdminService } from '../admin.service';

@Injectable({
  providedIn: 'root',
})
export class Adminuser implements Resolve<any> {
     constructor(private router: Router, private admin:AdminService) {  }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     
  

            return this.admin.getMenusPk(route.params.id);
        }
}
