<app-header
  [navbarBrandRouterLink]="['/']"
  [fixed]="true"
 
  [navbarBrandText]="{ icon: 'A',text: 'ALICORN'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="''" [asideMenuToggler]=false
[mobileAsideMenuToggler]=false>
      <cui-breadcrumb> </cui-breadcrumb>
      <app-navbtn class="ml-auto"></app-navbtn>
  <ul class="nav navbar-nav ">
  
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <i class="icon-user icons font-lg rounded-circle border p-1 border-primary"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Account</strong></div>
        
        <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
     <div class="divider"></div>
     
        <a class="dropdown-item" [routerLink]="" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
   
</app-header>

<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
   <!-- <app-sidebar-minimizer></app-sidebar-minimizer>-->
  </app-sidebar>
  <!-- Main content -->
  <main class="main position-relative">
       <app-indeterminate></app-indeterminate>
  <div class="container-fluid p-0">
     <div class="row mb-4 bg-white border-bottom no-gutters">
     <div class="col-md-6">

          </div>
          <div class="col-md-6">
       
         </div>


         </div>
     </div>
  
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>

</div>
<app-footer class="justify-content-center bg-white">
  <span>&copy; {{currentyear}}  <span class="text-primary">ALICORN</span> .</span>
 
</app-footer>

