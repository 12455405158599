import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';

import { BehaviorSubject,Observable, of, EMPTY,from,Subject }  from 'rxjs';

import { mergeMap, take,catchError,map }         from 'rxjs/operators';

import { OrdersService } from '../orders.service';

@Injectable({
  providedIn: 'root',
})


export class Orderdetails implements Resolve<any> {
     constructor(private router: Router, private os:OrdersService) {  }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
            let orderid = route.paramMap.get('orderid');
            return this.os.getOrderDetails(orderid);
        }
}
