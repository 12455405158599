import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';

import { BehaviorSubject, Observable, of, EMPTY, from, Subject } from 'rxjs';

import { mergeMap, take, catchError, map } from 'rxjs/operators';

import { CustomersService } from '../customers.service';
import { OrdersService } from '../orders.service';
import { EmailService } from '../email.service';

@Injectable({
  providedIn: 'root',
})
export class Customerdetails implements Resolve<any> {
  constructor(private router: Router, private cs: CustomersService, private os: OrdersService, private es: EmailService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let customerid = route.paramMap.get('id');

    var customerdata = this.cs.getCustomerDetailsPk(customerid).pipe(
      map((response: any) => {
        response = response[0];
        var userorder = [], emailsent = [];
         this.os.getUserOrders(response.publickey).subscribe(orders => {
          for (var li in orders) {
            userorder.push(orders[li]);
          }
        });

        this.es.getUserEmailSent(response.publickey).subscribe(emails => {
          for (var li in emails) {
            emails[li]["isExpanded"] = true;
            emailsent.push(emails[li]);
          }
        });
        response["order"] = userorder;
        response["emailsent"] = emailsent;
        return response;

      })
    );
    return customerdata;
  }
}
