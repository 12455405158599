import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { INavData } from '@coreui/angular';
import { navItems } from '../../_nav';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  Cyear: any = "";
  currentyear: number = new Date().getFullYear();

  keyMap = { menuName: "name", menuURL: "url", parentId: "parentId", id: "id" };
  constructor(private authenticationService: AuthService, private router: Router, private route: ActivatedRoute) {
    var d = new Date();
    this.Cyear = d.getFullYear();
    var initdata = this.route.snapshot.data['init'];

    localStorage.setItem("links", JSON.stringify(initdata[0]));

    var lfhdata = initdata[0].filter(item => {

      return item.isLHS == 1

    });

    var treeview: INavData[] = this.getNestedChildren(lfhdata, 0);
    setTimeout(() => {
      this.navItems = treeview;

    }, 150);


  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;

  }
  logout() {

    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  getNestedChildren(arr, parent) {
    var out = []
    for (var i in arr) {

      var newObject = {};
     
      if (arr[i].parentId == 0) {
        arr[i]["attributes"]={"class":"fw-600 px-2 fs-15"};
      }else{
        arr[i]["attributes"]={"class":"fs-13 fw-500 py-2 pe-1"};
      }
      if (arr[i].parentId == parent) {
        var children = this.getNestedChildren(arr, arr[i].id)

        if (children.length) {
          arr[i].children = children;
         
        }
        delete arr[i]["publicKey"];
        delete arr[i]["roleName"];
        delete arr[i]["isLHS"];
        delete arr[i]["isDisabled"];
        arr[i]["icon"]="";
        out.push(arr[i])
      }
    }
    return out
  }
}
