<div class="app-body">
    <main class="main d-flex align-items-center">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4 mx-auto" style="max-width:420px;">
            <div class="card shadow-sm   rounded-3">
                <div class="card-header px-4 border-0 bg-white">
                    <h4>Reset account password</h4>
                </div>
                <div class="card-body px-4 pb-4">
                    <p>Enter a new password</p>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <input class="form-control form-control-lg" formControlName="password"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" type="password" placeholder="Password"/>
                        </div>
                        <div class="form-group">
                            <input class="form-control form-control-lg" formControlName="confirmpassword"  [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" type="password" placeholder="Confirm password"/>
                        </div>
                        <div class="row justify-content-center">
                
                            <div class="col-12 text-center my-3">
                              <button type="submit" class="btn btn-primary btn-block rounded-3   fs-16 px-4" [ladda]="loading" [disabled]="loading" data-style="expand-right" >Submit</button>
                                
                            </div>

                            </div>
                    </form>
                    </div>

            </div>
            </div>
            </div>

            <div class="row">
                <div class="col-md-5 mx-auto">
                  <div class="mt-3"><p class="text-center ">By continuing, you are indicating that you have read and agree to our <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.</p></div>
                  <div class="mt-3 text-center">
                    <p>Need help? <a href="mailto:support@alicorn.co">support@alicorn.co</a></p>
                  </div>
                  </div>
                  </div>
            </div>
            </main>
            </div>

            <app-footer class="justify-content-center bg-transparent border-0">
                <span>&copy; {{currentyear}}  <span class="text-primary">ALICORN</span>. All rights reserved.</span>
               
              </app-footer>
              <toaster-container [toasterconfig]="toasterconfig"></toaster-container>