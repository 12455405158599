import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';


import { BehaviorSubject,Observable, of, forkJoin}  from 'rxjs';

import { mergeMap, take,catchError,map }         from 'rxjs/operators';

import { SettingService } from '../setting.service';

@Injectable({
  providedIn: 'root',
})
export class Doctorform implements Resolve<any> {
     constructor(private router: Router, private ss:SettingService) {  }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

     var States=this.ss.getStates();
        
            return forkJoin(States);
        }
}
