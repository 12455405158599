<div class="app-body">
    <main class="main d-flex align-items-center">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4 mx-auto" style="max-width:420px;">
            <div class="card shadow-sm   rounded-3">
                <div class="card-header px-4 border-0 bg-white">
                    <h4>Find Your Account</h4>
                </div>
                <div class="card-body px-4 pb-4">
                    <p>Please enter your email address to search for your account.</p>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="input-group input-group-lg mb-4">
                            <div class="input-group-prepend  position-absolute top-50 translate-middle-y" style="z-index: 1;">
                              <span class="input-group-text bg-transparent border-0 fw-bold text-secondary"><i class="icon-user"></i></span>
                            </div>
                            <input type="email" class="form-control rounded-3 position-relative ps-5 text-dark" style="z-index: 0;"    placeholder="Email" formControlName="username"  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autocomplete="username" required>
                          </div>

                          <div class="row justify-content-center">
                
                            <div class="col-12 text-center my-3">
                              <button type="submit" class="btn btn-primary btn-block rounded-3   fs-16 px-4" data-style="expand-right" [ladda]="loading" [disabled]="loading">Continue</button>
                                
                            </div>

                            </div>
                            
                    </form>
                    <div class="col-12 text-center  my-3 ">
                       Return to <a [routerLink]="[ '/login' ]" class="btn btn-link px-0">Login</a> 
                       
                      </div>
                    </div>
                    </div>
            </div>
            </div>

            <div class="row">
                <div class="col-md-5 mx-auto">
                  <div class="mt-3"><p class="text-center ">By continuing, you are indicating that you have read and agree to our <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.</p></div>
                  <div class="mt-3 text-center">
                    <p>Need help? <a href="mailto:support@alicorn.co">support@alicorn.co</a></p>
                  </div>
                  </div>
                  </div>
            </div>
            </main>
            </div>
            <toaster-container [toasterconfig]="toasterconfig"></toaster-container>
            <app-footer class="justify-content-center bg-transparent border-0">
                <span>&copy; {{currentyear}}  <span class="text-primary">ALICORN</span>. All rights reserved.</span>
               
              </app-footer>
        