import { Component,OnInit,ViewEncapsulation } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule }  from '@angular/common';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { ToasterModule, ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
import { AuthService } from '../../../services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
    styleUrls: ['../../../../scss/vendors/toastr/toastr.scss','../../../../../node_modules/ladda/dist/ladda-themeless.min.css'],
     encapsulation: ViewEncapsulation.None,
     providers: [ToasterService]
})
export class LoginComponent  {
    loginForm: FormGroup;
 loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    currentyear: number = new Date().getFullYear();
    private toasterService: ToasterService;
     public toasterconfig: ToasterConfig =
       new ToasterConfig({
         tapToDismiss: true,
         timeout: 5000
       });
constructor(private authS: AuthService,private formBuilder: FormBuilder,private route: ActivatedRoute,
        private router: Router,toasterService: ToasterService){this.toasterService = toasterService;
    
}
    
      ngOnInit() {
     
this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
         
  }
    
    get f() { return this.loginForm.controls; }
    
   onSubmit() {
  let self=this;
   this.submitted = true;
 if (this.loginForm.invalid) {
  //this.alertService.error("User name and Password required",AlertPosition.CenterTop);
  this.toasterService.pop('error', 'Error', 'User name and Password required');
            return;
        }
           this.loading = true;
             setTimeout(()=>{
                 
  this.authS.login(this.f.username.value, this.f.password.value).pipe(first())
            .subscribe(
                data => {
                  
                  if(!data){
                      this.loading = false;
                       this.submitted = false;
                      // this.alertService.error("username or password did not match *",AlertPosition.CenterTop);
                      this.toasterService.pop('danger', 'Error', 'username or password did not match *');
                     }else{
                         if(this.route.snapshot.queryParams.returnUrl){
                            this.router.navigate([this.route.snapshot.queryParams.returnUrl]);
                            }else{
                               this.router.navigate(['/']); 
                            }

                     }
               
                },
                error => {
                  this.toasterService.pop('error', 'Error', 'username or password did not match *');
                    this.loading = false;
                });
   },3000);
  }
}
