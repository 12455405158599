<div class="card">
    <div class="card-header bg-white"><h5>{{PageTitle}}</h5></div>
<div class="card-body table-responsive">
    
    <table class="table table-sm">
    <thead>
        <tr>
            <th>Sl No</th>
             
        <th *ngFor="let name of column">
          <span *ngIf="name!='PublicKey'">{{name}}</span>  
        </th>
           
           
        </tr>
        </thead>
        
        <tbody>
        <tr *ngFor="let row of list ;let inx=index;"> 
            <td>{{inx+1}}</td>
              
            <td *ngFor="let name of column">
           <span *ngIf="name!='PublicKey' && name!='FullName'"> {{row[name]}}</span>
           <span *ngIf="name=='FullName'"><a  [routerLink]="['/members/edit', row.PublicKey]" target="_blank"> {{row[name]}}</a></span>
            </td>
            
          
            </tr>
        
        </tbody>
    </table>
        </div>
    </div>