<div class="row mb-3">
    <div class="col-md-8">
        <div class="card card-body  "> 
            <div class="row gx-1" >
<div class="col"><select
    id="sposordd"
    [(ngModel)]="SponsorsKey"
    class="form-select form-select-sm"
    
  >
    <option value="0" selected>Filter By Sponsor</option>
    <option
      *ngFor="let Sponsor of Sponsorslist"
      [value]="Sponsor.PublicKey"
    >
      {{ Sponsor.Name }}
    </option>
  </select></div>

  <div class="col"><select
    id="divisionid"
    [(ngModel)]="DivisionKey"
    class="form-select form-select-sm" 
  >
    <option value="0" selected>Filter By Division</option>
    <option
      *ngFor="let division of DivisionFilterList | optionfilter:{'mapkey':'SponsorKey','mapval':SponsorsKey}"
      [value]="division['PublicKey']" 
    >
      {{ division["Name"] }}
    </option>
  </select></div>

  <div class="col"><select
    id="landd"
    [(ngModel)]="PlanKey"
    class="form-select form-select-sm"
    
  >
    <option value="0" selected>Filter By Plan</option>
    <option *ngFor="let Plan of Planlist" [value]="Plan.publicKey">
      {{ Plan.name }}
    </option>
  </select></div>
  <div class="col-md-3">
    <input type="text"
       placeholder="Datepicker"
       class="form-control"
       bsDaterangepicker   [bsConfig]="{ isAnimated: true,containerClass:'theme-blue', dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true }"  [(ngModel)]="daterangepickerModel"  (bsValueChange)="onDateValueChange($event)">
      </div> 
  <div class="col-md-1">
    <button type="button" class="btn btn-primary rounded-circle px-2" (click)="filterdb($event)">Go</button>
  </div>

            </div>
            
        
        


        </div>
    </div>

</div>
 
<div class="row">
    <div class="col-md-6 col-lg-3 ">
        <div class="card card-body d-flex flex-column justify-content-between align-items-center"> 
            <p  >Total number of active members</p>
            <h3 class="mb-0" ><a href="#" id="totalactivemember" data-drill="TotalActive" (click)="drilldown($event,'TotalActive','activemember')">0</a></h3>
        </div>
    </div>
    <div class="col-md-6   col-lg-3">
        <div class="card card-body d-flex flex-column align-items-center justify-content-between"> 
            <p  >Total number of active adult members</p>
            <h3 class="mb-0" ><a href="#" id="totaladultmember" data-drill="TotalAdultActive" (click)="drilldown($event,'TotalAdultActive','activeadult')">0</a></h3>
        </div>
    </div>
    <div class="col-md-6  col-lg-3">
        <div class="card card-body d-flex flex-column align-items-center justify-content-between"> 
            <p  >Total number of active adult members with email</p>
            <h3 class="mb-0" ><a href="#" id="totaladultmemberwithemail" data-drill="TotalEmailActive" (click)="drilldown($event,'TotalEmailActive','activeadultemail')">0</a></h3>
        </div>
    </div>
    <div class="col-md-6 col-lg-3">
        <div class="card card-body d-flex flex-column align-items-center justify-content-between"> 
            <p  >Total number of active adult members logged in</p>
            <h3 class="mb-0" ><a href="#" id="totaladultmemberactive" data-drill="TotalAlicornActive" (click)="drilldown($event,'TotalEmailActive','activeadulelogged')">0</a></h3>
        </div>
    </div>
     

</div>

<div class="row">
  <div class="col-md-4">
<div class="card">
  <div class="card-header bg-white">
    <h5 class="card-title mb-0 text-primary-500">Visit History</h5>
  </div>
  <div class="card-body table-responsive" style="max-height: 500px;">
    <table class="table table-sm">
    <thead>
        <tr> 
        <th *ngFor="let name of visitcolumn" [ngClass]="{'text-center':name =='TotalVisits'}">{{name}}</th>
        </tr>
        </thead>
        
        <tbody>
        <tr *ngFor="let row of visitlist;let inx=index;"> 
            <td *ngFor="let name of visitcolumn" [ngClass]="{'text-center':name =='TotalVisits'}">
              <span *ngIf="name !='TotalVisits'">
            {{row[name]}}
          </span>
          <span *ngIf="name =='TotalVisits'" class=" fs-14 fw-bold  text-success-emphasis bg-success-subtle badge border border-success-subtle">
            <a href="#"(click)="drilldown($event,row['PageName'],'visit')">{{row[name]}}</a>
            
          </span>
            </td>
            
          
            </tr>
        
        </tbody>
    </table>
    </div>
</div>
  </div>
 

  <div class="col-md-4">

<div class="card">
  <div class="card-header bg-white">
    <div class="row">
      <div class="col-md-4"><h5 class="card-title mb-0">Email History</h5></div>
      <div class="col-md-8">
        
      </div>

    </div>
    
  </div>
  <div class="card-body table-responsive" style="max-height: 500px;">
    <table class="table table-sm">
    <thead>
        <tr> 
        <th *ngFor="let name of emailcolumn" [ngClass]="{'text-center':name =='TotalEmails'}">{{name}}</th>
        </tr>
        </thead>
        
        <tbody>
        <tr *ngFor="let row of emailsummarylist;let inx=index;"> 
            <td *ngFor="let name of emailcolumn" [ngClass]="{'text-center':name =='TotalEmails'}">
              <span *ngIf="name !='TotalEmails'">
                {{row[name]}}
              </span>
              <span *ngIf="name =='TotalEmails'" class=" fs-14 fw-bold  text-success-emphasis bg-success-subtle badge border border-success-subtle">
                <a href="#"(click)="drilldown($event,row['EmailCondition'],'email')">{{row[name]}}</a>
              </span>
            </td>
            
          
            </tr>
        
        </tbody>
    </table>
    </div>
</div>
  </div>

  <div class="col-md-4">

    <div class="card">
      <div class="card-header bg-white">
        <div class="row">
          <div class="col-md-4"><h5 class="card-title mb-0">SMS History</h5></div>
          <div class="col-md-8">
            
          </div>
    
        </div>
        
      </div>
      <div class="card-body table-responsive" style="max-height: 500px;">
        <table class="table table-sm">
        <thead>
            <tr> 
            <th *ngFor="let name of smscolumn" [ngClass]="{'text-center':name =='TotalEmails'}">{{name}}</th>
            </tr>
            </thead>
            
            <tbody>
            <tr *ngFor="let row of smsummarylist;let inx=index;"> 
                <td *ngFor="let name of smscolumn" [ngClass]="{'text-center':name =='TotalSMS'}">
                  <span *ngIf="name !='TotalSMS'">
                    {{row[name]}}
                  </span>
                  <span *ngIf="name =='TotalSMS'" class=" fs-14 fw-bold  text-success-emphasis bg-success-subtle badge border border-success-subtle">
                    <a href="#"(click)="drilldown($event,row['SMSContents'],'sms')">{{row[name]}}</a>
                  </span>
                </td>
                
              
                </tr>
            
            </tbody>
        </table>
        </div>
    </div>
      </div>

</div>